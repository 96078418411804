var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
var PaymentCheckService = /** @class */ (function () {
    function PaymentCheckService() {
    }
    /**
     * 查询ptp记录
     */
    PaymentCheckService.prototype.queryPtpRecord = function (caseId, page, loading) {
        return this.netService.send({
            server: businessService.paymentCheckController.queryPtpRecord,
            data: {
                caseId: caseId
            },
            page: page,
            loading: loading
        });
    };
    /**
     * 查询cp记录
     */
    PaymentCheckService.prototype.queryCpRecord = function (caseId, page, loading) {
        return this.netService.send({
            server: businessService.paymentCheckController.queryCpRecord,
            data: {
                caseId: caseId
            },
            page: page,
            loading: loading
        });
    };
    /**
     * 跳票
     */
    PaymentCheckService.prototype.bouncedCheck = function (data, loading) {
        return this.netService.send({
            server: businessService.paymentCheckController.bouncedCheck,
            data: data,
            loading: loading
        });
    };
    /**
       * ptp转cp
       */
    PaymentCheckService.prototype.changeStatus = function (data) {
        return this.netService.send({
            server: businessService.paymentCheckController.changeStatus,
            data: {
                id: data.id,
                reason: data.reason,
                caseId: data.caseId,
                caseType: data.caseType
            }
        });
    };
    /**
   * 查询pc记录
   */
    PaymentCheckService.prototype.queryReplyRecord = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.paymentCheckController.queryReplyRecord,
            data: {
                principalId: data.principalId,
                personalName: data.personalName,
                isReply: data.isReply,
                exportState: data.exportState,
                applyName: data.applyName,
                certificateNo: data.certificateNo,
                batchNumber: data.batchNumber,
                applyDateStart: data.applyDate[0],
                applyDateEnd: data.applyDate[1],
            },
            loading: loading,
            page: page,
            sort: sort
        });
    };
    /**
     * 回复cp
     */
    PaymentCheckService.prototype.replyPaymentCheck = function (data) {
        return this.netService.send({
            server: businessService.paymentCheckController.replyPaymentCheck,
            data: {
                id: data.id,
                fallBackAmount: data.fallBackAmount,
                remark: data.remark
            }
        });
    };
    __decorate([
        Inject(NetService)
    ], PaymentCheckService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], PaymentCheckService.prototype, "queryPtpRecord", null);
    __decorate([
        Debounce()
    ], PaymentCheckService.prototype, "bouncedCheck", null);
    __decorate([
        Debounce()
    ], PaymentCheckService.prototype, "queryReplyRecord", null);
    return PaymentCheckService;
}());
export { PaymentCheckService };
