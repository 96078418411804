var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import RepaymentManagePending from "~/components/assistant-manage/repayment-manage/repayment-manage-pending.vue";
import RepaymentManageHandled from "~/components/assistant-manage/repayment-manage/repayment-manage-handled.vue";
import RepaymentManageResolve from "~/components/assistant-manage/repayment-manage/repayment-manage-resolve.vue";
import FollowRecord from "~/components/approval-manage/follow-record.vue";
import { ApprovalExportManageService } from '~/services/dataimp-service/approval-export-manage.service.ts';
import RepaymentReply from "~/components/assistant-manage/repayment-manage/repayment-reply.vue";
import PaymentFileUpload from "~/components/assistant-manage/payment-file-upload.vue";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
var RepaymentManage = /** @class */ (function (_super) {
    __extends(RepaymentManage, _super);
    function RepaymentManage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.repaymentModel = {
            principalName: "",
            batchNumber: "",
            personalName: "",
            applyRealName: "",
            certificateNo: "",
            applyDate: [],
            caseNumber: ""
        };
        _this.approvalCommitLoading = false;
        // 默认激活页面
        _this.activeName = "pending-operate";
        _this.approvalRules = {
            result: [{ required: true, message: "请选择审批结果", trigger: "change" }]
        };
        _this.rules = {
            certificateNo: [{ min: 6, message: '请输入至少6位的身份证号', trigger: 'blur' }]
        };
        _this.assistDataSet = null;
        _this.assistObj = {};
        _this.dialog = {
            approvedialog: false,
            caseImport: false
        };
        _this.repaymentRowData = {};
        _this.selectedList = [];
        return _this;
    }
    RepaymentManage.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 切换tab页面刷新对应的页面
     */
    RepaymentManage.prototype.handleTabClick = function (tab, event) {
        this.activeName = tab.name;
        this.resetSearchFelid();
        this.refreshData();
    };
    /**
     * 刷新数据
     */
    RepaymentManage.prototype.refreshData = function () {
        var tabdom = this.$refs[this.activeName];
        if (tabdom) {
            tabdom.refreshData();
        }
    };
    /**
     * 弹出框
     */
    RepaymentManage.prototype.approvedMeth = function (rowdata) {
        this.dialog.approvedialog = true;
        this.repaymentRowData = rowdata;
    };
    /**
     * reset搜索框
     */
    RepaymentManage.prototype.resetSearchFelid = function () {
        this.repaymentModel = {
            principalName: "",
            batchNumber: "",
            personalName: "",
            applyName: "",
            certificateNo: "",
            applyDate: []
        };
    };
    /**
     * 数据导出
     */
    RepaymentManage.prototype.exportCase = function () {
        var _this = this;
        var pendingOperate = this.$refs['pending-operate'];
        if (pendingOperate.selectionList.length == 0) {
            this.$message('请选择要导出的数据!');
            return;
        }
        var ids = pendingOperate.selectionList.map(function (v) { return v.id; });
        this.$confirm('您确定要导出所选数据吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(function () {
            _this.loading.state = true;
            _this.approvalExportManageService.exportPaymentCheck(ids, _this.loading)
                .subscribe(function (data) {
                _this.$message.success('导出成功'), _this.refreshData();
            }, function (err) { });
        }).catch(function () { });
    };
    /**
     * 案件导入
     */
    RepaymentManage.prototype.importCase = function () {
        this.dialog.caseImport = true;
    };
    __decorate([
        Dependencies(PageService)
    ], RepaymentManage.prototype, "pageService", void 0);
    __decorate([
        Dependencies(ApprovalExportManageService)
    ], RepaymentManage.prototype, "approvalExportManageService", void 0);
    RepaymentManage = __decorate([
        Layout("workspace"),
        Component({
            components: {
                FollowRecord: FollowRecord,
                DataForm: DataForm,
                DataBox: DataBox,
                RepaymentManagePending: RepaymentManagePending,
                RepaymentManageHandled: RepaymentManageHandled,
                RepaymentReply: RepaymentReply,
                RepaymentManageResolve: RepaymentManageResolve,
                PaymentFileUpload: PaymentFileUpload,
                PrincipalBatch: PrincipalBatch
            }
        })
    ], RepaymentManage);
    return RepaymentManage;
}(Vue));
export default RepaymentManage;
